import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { HeadingDefaultWhite } from "./Headings";

import cctv from "../img/csp-cctv.svg";
import solar from "../img/csp-solar.svg";
import communication from "../img/csp-communication.svg";
import itequvalant from "../img/csp-itequvalant.svg";
import cabling from "../img/csp-cablinginfrastructure.svg";
import network from "../img/csp-network.svg";
import { Link } from "react-router-dom";

const ServiceData = [
  { icon: cctv, alt: "CCTV", title: "CCTV", description: 'Specializing in Analog and IP Camera Installations.', delay: "100",  },
  {  icon: solar, alt: "Solar", title: "Solar", description: 'Residential, Commercial, Agricultural, Industrial, Net metering.', delay: "200", },
  {  icon: itequvalant, alt: "IT Equvalant", title: "IT Equvalant", description: 'Laptops, PCs, Printers/Scanners, Servers, and Workstations.', delay: "300",},
  {  icon: cabling, alt: "Cabling Infrastructure", title: "Cabling Infrastructure", description: 'LAN, WAN, Fiber, Wireless (Point to Point), and Power Cabling (for UPS) installations.', delay: "400", },
  {  icon: communication, alt: "Communication", title: "Communication", description: 'PABX, IP Phone, VOIP, VPN, and Voice Line solutions for seamless communication.', delay: "500", },
  {  icon: network, alt: "Network", title: "Network", description: 'Controlled Environment WiFi, Managed/Unmanaged Switches, and Routers.', delay: "600", },
];
export default function ServicesHome() {
  return (
    <>
      <section data-aos="fade-up" className="services">
        <Container fluid>
          <Row>
            <Col xs={12} md={4} className="intro">
              <HeadingDefaultWhite
                title="Our Services"
                subtitle="Empowering Solutions for Your Needs"
              />
              <p>
                Specializing in CCTV, Solar, IT, Networking, Power Solutions,
                Security, Access Control, and Software Integration.
              </p>
              <Link to="/Services" className="btn btn-primary">
                View More <i class="fa-solid fa-arrow-right"></i>
              </Link>
            </Col>
            <Col xs={12} md={8} className="service-list text-white">
              <Row className="content">
                {ServiceData.map((service, index) => (
                  <Col xs={12} md={6} className="item" key={index} data-aos-delay={service.delay}>
                    <Row>
                      <Col xs={12} md={2} className="icon">
                        <Image src={service.icon} alt={service.alt} />
                      </Col>
                      <Col xs={12} md={10} className="service-type">
                        <h5>{service.title}</h5>
                        <p>{service.description}</p>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
