import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image, Col, Container, Row, Card, CardBody } from "react-bootstrap";
import { HeadingDefault, HeadingDefaultCenter } from "./Headings";

import member1 from "../img/team/irshadulHaq.jpg";
import member2 from "../img/team/noorAhmad.jpg";
import member3 from "../img/team/asifmehmood.jpg";
import member4 from "../img/team/ayaz.jpg";
import member5 from "../img/team/tanveer.jpg";


const ClientSlider = () => {
  const settings = {
    
    dots: false,
    arrows: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const teamMembers = [
    {
      name: "Irshad-Ul-Haq",
      position: "Director",
      image: member1,
    },
    {
      name: "Noor Ahmad",
      position: "CFO",
      image: member2,
    },
    {
      name: "Asif Mehmood",
      position: "Regional Manager",
      image: member3,
    },
    {
      name: "Muhammad Ayaz",
      position: "Business Development Executive",
      image: member4,
    },
    {
      name: "Tanveer Ahmad",
      position: "Branch Manager",
      image: member5,
    },
    {
      name: "Noor Ahmad",
      position: "CFO",
      image: member2,
    },
    {
      name: "Asif Mehmood",
      position: "Regional Manager",
      image: member3,
    },
    {
      name: "Muhammad Ayaz",
      position: "Business Development Executive",
      image: member4,
    },
  ];

  return (
    <section className="teamslider" data-aos="fade-up">
      <HeadingDefaultCenter
        title="Professionals"
        subtitle="Our Collaborative Team"
      />
      <Container>
        <Row>
          <Slider {...settings}>
            {teamMembers.map((member, index) => (
              <Col key={index} className="teamMember">
              <Card>
                <Card.Img variant="top" src={member.image} alt={member.name} />
                <Card.Body>
                  <Card.Title>{member.name}</Card.Title>
                  <Card.Text>{member.position}</Card.Text>
                </Card.Body>
              </Card>
              </Col>
            ))}
          </Slider>
        </Row>
      </Container>
    </section>
  );
};

export default ClientSlider;
