import React from 'react'
import { Image, Col, Container, Row, Card, CardBody } from "react-bootstrap";
import Header from "./InnerHeader";
import { HeadingDefault } from "./Headings";
import ClientSlider from "./ClientsSlider";
import QuichAccess from "./QuichAccess";

import headerbg from '../img/bg-inner.jpg'

import brand1 from "../img/brands/anviz.png";
import brand2 from "../img/brands/cisco.png";
import brand3 from "../img/brands/cpplus.png";
import brand4 from "../img/brands/dlink.png";
import brand5 from "../img/brands/hikvision.png";
import brand6 from "../img/brands/homeywell.png";
import brand7 from "../img/brands/inim.png";
import brand8 from "../img/brands/itc.png";
import brand9 from "../img/brands/mikrotik.png";
import brand10 from "../img/brands/toa.png";
import brand11 from "../img/brands/tplink.png";
import brand12 from "../img/brands/ubiquiti.png";
import brand13 from "../img/brands/zkt.png";

const data = [
  { brand: brand1, alt: "anviz", delay: "100", },
  { brand: brand2, alt: "cisco", delay: "200", },
  { brand: brand3, alt: "cpplus", delay: "300", },
  { brand: brand4, alt: "dlink" , delay: "500",},
  { brand: brand5, alt: "hikvision", delay: "100", },
  { brand: brand6, alt: "homeywell", delay: "200", },
  { brand: brand7, alt: "inim", delay: "300", },
  { brand: brand8, alt: "itc" , delay: "500",},
  { brand: brand9, alt: "mikrotik", delay: "100", },
  { brand: brand10, alt: "toa", delay: "200", },
  { brand: brand11, alt: "tplink", delay: "300", },
  { brand: brand12, alt: "ubiquiti " , delay: "500",},
  { brand: brand13, alt: "zkt " , delay: "500",},
];
export default function Brands() {
  return (
    <>
    <Header backgroundImage={headerbg} title="Brands"/>
    <section className='clients' data-aos="fade-up">
        <Container>
        <HeadingDefault
                title="Our Trusted Brands"
                subtitle="We recommend and utilize trusted brands that have earned our confidence, ensuring the utmost reliability and satisfaction for our clients."
              />
          <Row className="justify-content-center align-items-center">
            {data.map((item, index) => (
              <Col key={index} xs={12} md={4} lg={3} className="mb-3 hvr-float-shadow"  data-aos="zoom-in" data-aos-delay={item.delay}>
                <Card>
                  <CardBody>
                    <Image src={item.brand} alt={item.alt} fluid/>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <QuichAccess/>
      <ClientSlider />
    </>
  )
}
