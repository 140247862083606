import { useState, useEffect } from "react";
import { Button, Row, Col, Container, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import slide1 from "../img/slide-ceo.jpg";
import slide2 from "../img/slide-solar.jpg";
import slide3 from "../img/slide-cctv.jpg";
import slide4 from "../img/slide-cabling.jpg";
import slide5 from "../img/slide-itequipments.jpg";
import slide6 from "../img/slide-security.jpg";

const carouselItems = [
  {
    heroImage: slide1,
    alt: "Welcome to the CSP",
    title: "Welcome to the CSP",
    subtitle: "Since 1996 we are experts in the global industry",
    description: "CCTV, solar, network infrastructure, IT equipment, communication systems, power backup solutions, cabling, security, access control, & software development.",
  },
  {
    heroImage: slide2,
    alt: "Solar Energy Solutions",
    title: "Powering the Future",
    subtitle: "Cutting-edge Solar Energy Solutions for a Sustainable Tomorrow",
    description: "Residential, Commercial, Agricultural,  Industrial, Net metering, Bank Lease",
  },
  {
    heroImage: slide3,
    alt: "Securing Your World",
    title: "Securing Your World",
    subtitle: "Advanced Surveillance Solutions for Comprehensive Security",
    description: "CCTV, Analog Camera Setup, IP Camera Setup, Zone Alarm System, Walk Through Gates, Scanner",
  },
  {
    heroImage: slide4,
    alt: "Building Connections",
    title: "Building Connections",
    subtitle: "Streamlined Cabling Infrastructure Solutions for Modern Connectivity",
    description: "Fiber, LAN, WAN, Wireless (Point to Point), Power Cabling, Telephone Cabling",
  },
  {
    heroImage: slide5,
    alt: "IT Excellence",
    title: "IT Excellence",
    subtitle: "Cutting-edge Solutions for Equipping Your Infrastructure",
    description: "Server, Desktop, Laptop, Projectors, Printer, Fax",
  },
  {
    heroImage: slide6,
    alt: "Secure Access Solutions",
    title: "Secure Access Solutions",
    subtitle: "Enhancing Control and Safety",
    description: "RFID, Finger Print Control, Finger Print & Face Detection, Attendance System",
  },
];

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) =>
        prevIndex === carouselItems.length - 1 ? 0 : prevIndex + 1
      );
    }, 12000); // Adjust the interval duration as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <Carousel
      fade
      controls={true}
      interval={null}
      activeIndex={index}
      onSelect={handleSelect}
      className="header-slider"
    >
      {carouselItems.map((item, idx) => (
        <Carousel.Item key={idx}>
          <img src={item.heroImage} alt={item.alt} />
          <Carousel.Caption>
            <Container>
              <Row className="justify-content-start align-items-center">
                <Col xs={12} md={7} data-aos="fade-right">
                  <h5>{item.title}</h5>
                  <h1>{item.subtitle}</h1>
                  <p>{item.description}</p>
                  <Link to="/Services">
                    <Button variant="light">
                      Our Solutions <i class="fa-solid fa-arrow-right"></i>
                    </Button>
                  </Link>
                  <Link to="/Contact">
                    <Button variant="outline-warning">
                      Contact Us <i class="fa-solid fa-arrow-right"></i>
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Container>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default ControlledCarousel;
