import React from "react";
import HeaderSlider from "./HomeSlider";
import Company from "./WhyUsHome";
import Businesses from "./BusinessList";
import ClientsSlider from "./ClientsSlider";
import ServicesHome from "./ServicesHome";
import Locations from "./Locations";
import TeamSlider from "./TeamSlider";
import SuccessStory from "./SuccessStory";
import QuichAccess from "./QuichAccess";
import Counter from "./Counter";
export default function () {

  return (
    <>
      <HeaderSlider />
      <Company/>
      <Businesses/>
      <Locations/>
      <ServicesHome/>
      <TeamSlider/>
      <Counter/>
      <SuccessStory/>
      <QuichAccess/>
      <ClientsSlider/>
    </>
  );
}
