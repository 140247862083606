import React from 'react'
import Header from "./InnerHeader";
import headerbg from "../img/bg-inner.jpg";
import { Container, Row, Col } from 'react-bootstrap';
export default function NoPage() {
  return (
    <>
    <Header backgroundImage={headerbg} title="No Page" />
    <section className='nopage'>
      <Container>
        <Row>
          <Col className='text-center'>
            <h1>Error / 404</h1>
            <p>Page not Found</p>
          </Col>
        </Row>
      </Container>
      </section>
    </>
  )
}
