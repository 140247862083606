import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image, Col, Container, Row, Card, CardBody } from "react-bootstrap";

import brand1 from "../img/brands/anviz.png";
import brand2 from "../img/brands/cisco.png";
import brand3 from "../img/brands/cpplus.png";
import brand4 from "../img/brands/dlink.png";
import brand5 from "../img/brands/hikvision.png";
import brand6 from "../img/brands/homeywell.png";
import brand7 from "../img/brands/inim.png";
import brand8 from "../img/brands/itc.png";
import brand9 from "../img/brands/mikrotik.png";
import brand10 from "../img/brands/toa.png";
import brand11 from "../img/brands/tplink.png";
import brand12 from "../img/brands/ubiquiti.png";
import brand13 from "../img/brands/zkt.png";

const data = [
  { brand: brand1, alt: "Anviz brand logo", delay: 100 },
  { brand: brand2, alt: "Cisco brand logo", delay: 200 },
  { brand: brand3, alt: "CP Plus brand logo", delay: 300 },
  { brand: brand4, alt: "D-Link brand logo", delay: 500 },
  { brand: brand5, alt: "Hikvision brand logo", delay: 100 },
  { brand: brand6, alt: "Honeywell brand logo", delay: 200 },
  { brand: brand7, alt: "Inim brand logo", delay: 300 },
  { brand: brand8, alt: "ITC brand logo", delay: 500 },
  { brand: brand9, alt: "Mikrotik brand logo", delay: 100 },
  { brand: brand10, alt: "TOA brand logo", delay: 200 },
  { brand: brand11, alt: "TP-Link brand logo", delay: 300 },
  { brand: brand12, alt: "Ubiquiti brand logo", delay: 500 },
  { brand: brand13, alt: "ZKT brand logo", delay: 500 },
];

const BrandsSlider = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="clients-slider" data-aos="fade-up">
      <Container>
        <Row>
          <Slider {...settings}>
            {data.map((item, index) => (
              <Col key={index} className="mb-3 hvr-float-shadow" data-aos="zoom-in" data-aos-delay={item.delay}>
                <Image src={item.brand} alt={item.alt} />
              </Col>
            ))}
          </Slider>
        </Row>
      </Container>
    </section>
  );
};

export default BrandsSlider;
