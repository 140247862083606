import React from "react";
import Socialmedia from "./Socialmedia";
import { NavLink } from "react-router-dom";
import {Row, Container } from "react-bootstrap";
export default function Topbar() {
  return (
    <>
      <div className="topbar align-items-center">
        <Container className="container d-flex justify-content-center justify-content-md-between">
          <ul className="contact-info d-flex align-items-center">
            <li>
              <b>
                <i className="fa-solid fa-envelope"></i>
              </b>
              <NavLink to="mailto:info@softagics.com">
                info@csp.com
              </NavLink>
            </li>
            <li>
              <b>
                <i className="fa-solid fa-phone"></i>
              </b>
              <NavLink to="tel:+923260667666">+92-32-60667666</NavLink>
            </li>
          </ul>
          <div className="social-links d-none d-md-flex align-items-center">
            <Socialmedia />
          </div>
        </Container>
      </div>
    </>
  );
}
