import React from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import trust from "../img/trust.svg";
import support from "../img/support.svg";
import expert from "../img/expert.svg";
export default function About() {
  const cards = [
    {
      title: "Trusted Services",
      icon: trust,
      subtitle: "Reliability You Can Count On",
      text: "We've earned the trust of our customers through reliability and excellence.",
      delay: "100"
    },
    {
      title: "24/7 Support",
      icon: support,
      subtitle: "Call: +92-32-60667666",
      text: "Round-the-clock assistance ensures we're here for you whenever you need us.",
      delay: "200"
    },
    {
      title: "Expert & Professional",
      icon: expert,
      subtitle: "Excellence in Every Interaction",
      text: "Our team consists of seasoned experts dedicated to delivering professional service.",
      delay: "300"
    },
    // Add more objects for additional cards if needed
  ];

  return (
    <section className="productsList" data-aos="fade-up">
      <Container>
        <Row className="justify-content-center">
          {cards.map((card, index) => (
            <Col key={index} xs={12} md={4} className='hvr-grow-shadow' data-aos="zoom-in" data-aos-delay={card.delay}>
              <Card>
                <Card.Header>
                <Row className="align-items-center">
                    <Col xs={12} md={2}>
                      <Image src={card.icon} alt={card.title} />
                    </Col>
                    <Col xs={12} md={10}>
                      <Card.Title>{card.title}</Card.Title>
                      <Card.Subtitle>{card.subtitle}</Card.Subtitle>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Card.Text>{card.text}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
