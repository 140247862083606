import React from 'react'
import { Container } from "react-bootstrap";
import { HeadingWhiteCenter } from "./Headings";
import { Link } from 'react-router-dom';

export default function QuichAccess() {
  return (
    <>
    <section className='quichAccess'  data-aos="fade-up">
    <Container className="content text-center">
    <HeadingWhiteCenter
                title="Get Free Consultation"
                subtitle="Unlock Your Potential: Claim Your Free Consultation"
              />
    <p>Call Us: +92-32-6066-7666 | +92-300-863-9171</p>
    <p>@ Monday - Saturday from 9:00 a.m to 5:00 p.m</p>
    <Link to='/Contact' className='btn btn-orange'>
                        Contact Us <i class="fa-solid fa-arrow-right"></i>
                      </Link>
    </Container>
    </section>
    </>
  )
}
