import { Container, Row, Col, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Socialmedia from "./Socialmedia";
import CSPLogo from '../img/logo.jpg';
const services = [
  { title: "CCTV", link: "/Services" },
  { title: "Computer sales", link: "/Services" },
  { title: "CCTV", link: "/Services" },
  { title: "solar energy solutions", link: "http://jadeedenergy.com/" },
  { title: "software Solution", link: "https://www.softagics.com/" },
  { title: "IT Equivalent", link: "/Services" },
  { title: "More...", link: "/Services" },
];
const products = [
  { title: "CSP Technologies", link: "/" },
  { title: "Jadeed Solar Energy", link: "http://jadeedenergy.com/" },
  { title: "Jadeed Munshi", link: "https://www.jadeedmunshi.com" },
  { title: "Softagics", link: "https://www.softagics.com/" },
];
const quickLink = [
  { title: "Company", link: "/Company" },
  { title: "Services", link: "/Services" },
  { title: "Businesses", link: "/Businesses" },
  { title: "Clients", link: "/Clients" },
  { title: "Careers", link: "/Careers" },
  { title: "Contact", link: "/Contact" },
];

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <Container>
        <Row className="footer-body">
          <Col xs={12} md={3}>
            <h5><Image src={CSPLogo} alt='CSP'/></h5>
            <p>
              We specialize in a comprehensive range of services, including
              computer sales, CCTV installation, solar solutions, software
              development, and mobile app development.
            </p>
            <Socialmedia />
          </Col>
          <Col xs={6} md={2}>
            <h5>Services</h5>
            <ul>
              {services.map((service, index) => (
                <li key={index}>
                  <NavLink to={service.link}  onClick={() => window.scrollTo(0, 0)}>{service.title}</NavLink>
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={6} md={2} className="mb-3">
            <h5>products</h5>
            <ul>
              {products.map((products, index) => (
                <li key={index}>
                  <NavLink to={products.link} onClick={() => window.scrollTo(0, 0)}>{products.title}</NavLink>
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={6} md={2} className="mb-3">
            <h5>Quick Links</h5>
            <ul>
              {quickLink.map((quickLink, index) => (
                <li key={index}>
                  <NavLink to={quickLink.link} onClick={() => window.scrollTo(0, 0)}>{quickLink.title}</NavLink>
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={6} md={3} className="mb-3">
            <h5>Contact</h5>
            <ul>
              <li>
                <b>
                  <i className="fa-solid fa-location-dot"></i>
                </b>
                <NavLink to="https://maps.app.goo.gl/nV3e84P3w9tuZhwb6">
                12-1, block A2 Opp. Cine Star ,Township, Lahore
                </NavLink>
              </li>
              
              <li className="email">
                <b>
                  <i className="fa-solid fa-envelope"></i>
                </b>
                <NavLink to="mailto:info@csp.com.pk">
                info@csp.com.pk
                </NavLink>
              </li>
              <li>
                <b>
                  <i className="fa-solid fa-phone"></i>
                </b>
                <NavLink to="tel:+923008639171">+92-300-863-9171</NavLink>
              </li>
              <li>
                <b>
                  <i className="fa-brands fa-whatsapp"></i>
                </b>
                <NavLink to="tel:+923260667666">+92-32-60667666</NavLink>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="copyright py-3">
          <Col xs={12}>
            Copyright © 1996 to {currentYear}{" "}
            <b>
              <NavLink to="/">csp.com.pk</NavLink>
            </b>
            . <span>All Rights are Reserved</span>{" "}
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
