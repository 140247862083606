import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
export default function Socialmedia() {
  return (
    <>
      <ul className="social">
        <li>
          <NavLink to="https://www.facebook.com/csp.com.pk">
            <FaFacebookF />
          </NavLink>
        </li>
        <li>
          <NavLink to="/Services">
            <FaInstagram />
          </NavLink>
        </li>
        <li>
          <NavLink to="/">
            <FaLinkedinIn />
          </NavLink>
        </li>
        <li>
          <NavLink to="tel:+923260667666">
            <FaWhatsapp />
          </NavLink>
        </li>
      </ul>
    </>
  );
}
