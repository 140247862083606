import React, { useState, useEffect } from "react";
import Header from "./InnerHeader";
import headerbg from "../img/bg-inner.jpg";
import { Image, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProductsList from "./TrustSupportExpert";
import { HeadingDefault } from "./Headings";
import about from "../img/about.jpg";
import Counter from "./Counter";
import ClientSlider from "./ClientsSlider";
import BrandsSlider from "./BrandsSlider";
import QuichAccess from "./QuichAccess";
export default function About() {
  const [yearsDifference, setYearsDifference] = useState(0);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startingYear = 1996;
    const difference = currentYear - startingYear;
    setYearsDifference(difference);
  }, []); // Empty dependency array ensures this effect runs only once after the component mounts

  return (
    <>
      <Header backgroundImage={headerbg} title="About Us" />
      <section className="about" data-aos="fade-up">
        <Container>
          <Row>
            <Col xs={12} md={5}>
              <Image src={about} alt="CSP Technologies" />
            </Col>
            <Col xs={12} md={7}>
              <HeadingDefault
                title={`Over ${yearsDifference} Years of Excellence, Trusted Services Every Time`}
              />
              <p>
                With a steadfast commitment to excellence spanning over 28
                years, our dedication to providing trusted services stands as
                our hallmark. Throughout our journey, we have honed our
                expertise, refined our methods, and cultivated enduring
                relationships with our clients. Every interaction with us is
                imbued with a legacy of reliability, professionalism, and
                unwavering quality. Our track record speaks volumes, echoing our
                unwavering dedication to surpassing expectations and delivering
                unparalleled results, time and time again. Trust us to be your
                partner in success, as we continue to redefine excellence in
                service provision, guided by our decades of experience and
                unwavering commitment to client satisfaction.
              </p>
              <Link to="/Projects" className="btn">
                Read More <i class="fa-solid fa-arrow-right"></i>
              </Link>
            </Col>
          </Row>
        </Container>
        <ProductsList />
      </section>
      <Counter />
      <section>
        <Container>
          <HeadingDefault title={`Our Trusted Brands`} />
        </Container>
        <BrandsSlider />
      </section>
      <QuichAccess />
      <ClientSlider />
    </>
  );
}
