import React, { useState } from "react";
import { HeadingDefault } from "./Headings";
import Header from "./InnerHeader";
import headerbg from "../img/bg-inner.jpg";
import {
  Container,
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  CardBody,
  Image,
} from "react-bootstrap";
import ClientSlider from "./ClientsSlider";
import QuichAccess from "./QuichAccess";
// Banks
import askari from "../img/clients/askari-bank.png";
import bop from "../img/clients/bop.png";
import hbl from "../img/clients/hbl.png";
import ztbl from "../img/clients/ztbl.png";
import nationalsavings from "../img/clients/nationalsavings.png";
import nbp from "../img/clients/nbp.png";
// Gov
import ccri from "../img/clients/ccri.png";
import dgkpolice from "../img/clients/dgkpolice.png";
import eCoP from "../img/clients/electionCommissionofPakistan.png";
import govpunjab from "../img/clients/govpunjab.png";
import highcourt from "../img/clients/highcourt.png";
import hrdc from "../img/clients/hrdc.png";
import mWasteManagement from "../img/clients/multanWasteManagement.png";
import pakArmycmh from "../img/clients/pakArmycmh.png";
import pitb from "../img/clients/pitb.png";
import punjab_police from "../img/clients/punjab_police.png";
import pakrail from "../img/clients/pakrail.png";
import danishschool from "../img/clients/dss.png";
// Semi Gov
import ptcl from "../img/clients/ptcl.png";
import qAzamSolarPower from "../img/clients/quaid-e-AzamSolarPower.png";
import dss from "../img/clients/dss.png";
// Private
import barkatfoods from "../img/clients/barkatfoods.png";
import hondabreeze from "../img/clients/hondabreeze.png";
import hondamultan from "../img/clients/hondamultan.png";
import hyundaiOASIS from "../img/clients/hyundaiOASIS.png";
import idealprinterpackages from "../img/clients/idealprinterpackages.png";
import kisaanseed from "../img/clients/kisaanseed.png";
import rbavari from "../img/clients/rbavari.png";
import rehmatfoods from "../img/clients/rehmatfoods.png";
import yaqoobsoap from "../img/clients/yaqoobsoap.png";
import kkmart from "../img/clients/kkmart.png";
import smfood from "../img/clients/smfood.png";


const gov = [
  { client: ccri, alt: "anviz", delay: "100" },
  { client: dgkpolice, alt: "cisco", delay: "200" },
  { client: eCoP, alt: "cpplus", delay: "300" },
  { client: govpunjab, alt: "dlink", delay: "500" },
  { client: highcourt, alt: "hikvision", delay: "100" },
  { client: hrdc, alt: "homeywell", delay: "200" },
  { client: mWasteManagement, alt: "inim", delay: "300" },
  { client: pakArmycmh, alt: "itc", delay: "500" },
  { client: pitb, alt: "mikrotik", delay: "100" },
  { client: punjab_police, alt: "toa", delay: "200" },
  { client: pakrail, alt: "pakrail", delay: "300" },
  { client: danishschool, alt: "danishschool", delay: "400" },
];
const semigov = [
  { client: ptcl, alt: "ptcl", delay: "100" },
  { client: qAzamSolarPower, alt: "Qaid e Azam Solar Power", delay: "200" },
  { client: dss, alt: "Danish School System", delay: "300" },
];
const priv = [
  { client: barkatfoods, alt: "barkat foods", delay: "100" },
  { client: hondabreeze, alt: "honda breeze", delay: "200" },
  { client: hondamultan, alt: "honda multan", delay: "300" },
  { client: hyundaiOASIS, alt: "hyundai OASIS", delay: "500" },
  { client: idealprinterpackages, alt: "ideal printer packages", delay: "200" },
  { client: kisaanseed, alt: "kisaan seed", delay: "300" },
  { client: rbavari, alt: "rbavari", delay: "500" },
  { client: rehmatfoods, alt: "rehmat foods", delay: "100" },
  { client: yaqoobsoap, alt: "yaqoob soap", delay: "200" },
  { client: kkmart, alt: "kkmart", delay: "300" },
  { client: smfood, alt: "sm food", delay: "400" },
];
const bank = [
  { client: askari, alt: "askari", delay: "100" },
  { client: bop, alt: "bop", delay: "200" },
  { client: hbl, alt: "hbl", delay: "300" },
  { client: ztbl, alt: "ztbl", delay: "500" },
  { client: nationalsavings, alt: "national savings", delay: "100" },
  { client: nbp, alt: "nbp", delay: "200" },
];
const allClients = [
  ...gov.map(item => ({ ...item, type: "gov" })),
  ...semigov.map(item => ({ ...item, type: "semigov" })),
  ...priv.map(item => ({ ...item, type: "priv" })),
  ...bank.map(item => ({ ...item, type: "bank" })),
];
export default function Clients() {
  const [key, setKey] = useState("tab1");
  return (
    <>
      <Header backgroundImage={headerbg} title="Clients" />
      <section  data-aos="fade-up">
        <Container>
          <HeadingDefault
            title="Our Relationships"
            subtitle="Connecting with Our Valued Partners: Building Relationships That Drive Success"
          />
          <Tabs
            id="controlled-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="tab1" title="All">
              <Row className="justify-content-center align-items-center">
                {allClients.map((item, index) => (
                  <Col
                    key={index}
                    xs={12}
                    md={4}
                    lg={3}
                    className="mb-3 hvr-float-shadow"
                    data-aos="zoom-in"
                    data-aos-delay={item.delay}
                  >
                    <Card>
                      <CardBody>
                        <Image src={item.client} alt={item.alt} fluid />
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Tab>
            <Tab eventKey="tab2" title="Government">
              <Row className="justify-content-center align-items-center">
                {gov.map((item, index) => (
                  <Col
                    key={index}
                    xs={12}
                    md={4}
                    lg={3}
                    className="mb-3 hvr-float-shadow"
                    data-aos="zoom-in"
                    data-aos-delay={item.delay}
                  >
                    <Card>
                      <CardBody>
                        <Image src={item.client} alt={item.alt} fluid />
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Tab>
            <Tab eventKey="tab3" title="Semi Government">
              <Row className="justify-content-center align-items-center">
                {semigov.map((item, index) => (
                  <Col
                    key={index}
                    xs={12}
                    md={4}
                    lg={3}
                    className="mb-3 hvr-float-shadow"
                    data-aos="zoom-in"
                    data-aos-delay={item.delay}
                  >
                    <Card>
                      <CardBody>
                        <Image src={item.client} alt={item.alt} fluid />
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Tab>
            <Tab eventKey="tab4" title="Private">
              <Row className="justify-content-center align-items-center">
                {priv.map((item, index) => (
                  <Col
                    key={index}
                    xs={12}
                    md={4}
                    lg={3}
                    className="mb-3 hvr-float-shadow"
                    data-aos="zoom-in"
                    data-aos-delay={item.delay}
                  >
                    <Card>
                      <CardBody>
                        <Image src={item.client} alt={item.alt} fluid />
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Tab>
            <Tab eventKey="tab5" title="Bank">
              <Row className="justify-content-center align-items-center">
                {bank.map((item, index) => (
                  <Col
                    key={index}
                    xs={12}
                    md={4}
                    lg={3}
                    className="mb-3 hvr-float-shadow"
                    data-aos="zoom-in"
                    data-aos-delay={item.delay}
                  >
                    <Card>
                      <CardBody>
                        <Image src={item.client} alt={item.alt} fluid />
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </section>
      <QuichAccess/>
      <ClientSlider />
    </>
  );
}
