import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav, Image } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { saveAs } from 'file-saver';
import Topbar from './Topbar';
import CSPLogo from '../img/logo.jpg';
import Presentation from '../CSP-Profile.pdf';

function Header() {
  const handleProfileDownload = () => {
    saveAs(Presentation, 'CSP-Profile.pdf'); // Put your PDF URL here.
  };

  const [navbarBackground, setNavbarBackground] = useState('transparent');
  const [hideTopbar, setHideTopbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      if (scrollPosition > 10) {
        setNavbarBackground('navbg');
        setHideTopbar(true);
      } else {
        setNavbarBackground('transparent');
        setHideTopbar(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="mainNav">
        {!hideTopbar && <Topbar />}
        <Navbar collapseOnSelect expand="lg" className={navbarBackground}>
          <Container>
            <Navbar.Brand as={NavLink} to="/">
              <Image src={CSPLogo} alt="CSP" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link as={NavLink} to="/About">
                  About
                </Nav.Link>
                <Nav.Link as={NavLink} to="/Businesses">
                  Businesses
                </Nav.Link>
                <Nav.Link as={NavLink} to="/Projects">
                  Projects
                </Nav.Link>
                <Nav.Link as={NavLink} to="/Services">
                  Services
                </Nav.Link>
                <Nav.Link as={NavLink} to="/Team">
                  Team
                </Nav.Link>
                <Nav.Link as={NavLink} to="/Clients">
                  Clients
                </Nav.Link>
                <Nav.Link as={NavLink} to="/Brands">
                  Brands
                </Nav.Link>
                <Nav.Link onClick={handleProfileDownload}>
                  Profile
                </Nav.Link>
                <Nav.Link as={NavLink} to="/Contact">
                  Contact
                </Nav.Link>
                <Nav.Link as={NavLink} to="/Quote" className="btn">
                  Get a Quote
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default Header;
