import React from "react";
import Header from "./InnerHeader";
import headerbg from "../img/bg-inner.jpg";
import { HeadingDefault } from "./Headings";
import ClientSlider from "./ClientsSlider";
import QuichAccess from "./QuichAccess";
import ContactForm from "./ContactForm";
import { Col, Container, Row } from "react-bootstrap";

export default function Quote() {
  return (
    <>
      <Header backgroundImage={headerbg} title="Quote" />
      <section>
        <Container>
          <Row>
            <Col>
            <HeadingDefault
                title="Tailored Solutions Await"
                subtitle="Request Your Personalized Quote Today!"
              />
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </section>
      <QuichAccess/>
      <ClientSlider />
    </>
  );
}
