import React, { useEffect } from 'react';
import "./App.css";
import "./Hover.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from './Components/Home.js'
import About from "./Components/About";
import Businesses from "./Components/Businesses";
import Projects from "./Components/Projects";
import Services from "./Components/Services";
import Team from "./Components/Team";
import Clients from "./Components/Clients";
import Brands from "./Components/Brands";
import Contact from "./Components/Contact";
import Quote from "./Components/Quote";
import NoPage from "./Components/NoPage";
import Test from "./Components/Test";
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return null;
}
  
function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: 'ease', // Easing function
      once: true, // Whether animation should happen only once
    });
  }, []);
  return (
    <>
      <BrowserRouter>
      <Header />
        <div className="content-body">
          <Routes>
            <Route index element={<Home />} />
            <Route  path="/About" element={<About />} />
            <Route  path="/Businesses" element={<Businesses />} />
            <Route  path="/Projects" element={<Projects />} />
            <Route  path="/Services" element={<Services />} />
            <Route  path="/Team" element={<Team />} />
            <Route  path="/Clients" element={<Clients />} />
            <Route  path="/Brands" element={<Brands />} />
            <Route  path="/Contact" element={<Contact />} />
            <Route  path="/Quote" element={<Quote />} />
            <Route path="*" element={<NoPage />} />
            <Route path="/Test" element={<Test />} />
          </Routes>
        </div>
        <Footer />
        <ScrollToTop />
      </BrowserRouter>
     
    </>
  );
}

export default App;
