import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import csptech from "../img/csptech.svg";
import softagics from "../img/softagics.svg";
import jadeedEnergy from "../img/jadeedEnergy.svg";
import jadeedMunshi from "../img/jadeedMunshi.svg";
import jadeedBazar from "../img/jadeedBazar.svg";
import jadeedEMS from "../img/jadeedEMS.svg";
import jadeedSales from "../img/jadeedSales.svg";
import jadeedWorkshop from "../img/jadeedWorkshop.svg";
export default function Businesses() {
  const businesses = [
    {
      title: "CSP Technologies",
      text: "IT services: CCTV, PBX, internet, networking, SMS, and hosting.",
      imageSrc: csptech,
      linkTo: "/Services",
      delay: "100"
    },
    {
      title: "Softagics",
      text: "Software House | web, desktop, mobile, bespoke solutions.",
      imageSrc: softagics,
      linkTo: "https://softagics.com",
      delay: "200"
    },
    {
      title: "Jadeed Energy Solution",
      text: "Versatile power solutions for solar, generator, UPS systems.",
      imageSrc: jadeedEnergy,
      linkTo: "http://jadeedenergy.com/",
      delay: "300"
    },
    // {
    //   title: "Jadeed Munshi",
    //   text: "Seamless financial and point-of-sale solutions.",
    //   imageSrc: jadeedMunshi,
    //   linkTo: "/",
    //   delay: "400"
    // },
    // {
    //   title: "Jadeed Workshop",
    //   text: "Capture all essential product details comprehensively.",
    //   imageSrc: jadeedWorkshop,
    //   linkTo: "/",
    //   delay: "500"
    // },
    // {
    //   title: "Jadeed EMS",
    //   text: "Education Management System for seamless learning.",
    //   imageSrc: jadeedEMS,
    //   linkTo: "/",
    //   delay: "600"
    // },
    // {
    //   title: "Jadeed Bazar",
    //   text: "End-to-end B2B solution for comprehensive business needs.",
    //   imageSrc: jadeedBazar,
    //   linkTo: "/",
    //   delay: "700"
    // },
    // {
    //   title: "Jadeed Sales",
    //   text: "Unified system for orders, quotations, work, and targets.",
    //   imageSrc: jadeedSales,
    //   linkTo: "/",
    //   delay: "800"
    // },
  ];
  return (
    <>
    <Container  data-aos="fade-up">
        <Row className="productList justify-content-center">
          {businesses.map((card) => (
            <Col key={card.id} xs={12} md={4} className="mb-3 hvr-float-shadow" data-aos="zoom-in" data-aos-delay={card.delay}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col xs={12} md={4}>
                      <Card.Img variant="top" src={card.imageSrc} />
                    </Col>
                    <Col xs={6} md={8}>
                      <Card.Title>{card.title}</Card.Title>
                      <Card.Text>{card.text}</Card.Text>
                      <Link to={card.linkTo}>
                        Read More <i class="fa-solid fa-arrow-right"></i>
                      </Link>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        </Container>
    </>
  );
}
