import React, { useState } from "react";

export default function Test() {
  const [data, setData] = useState(0);
  
  function updateData() 
  {
    setData(data+1);
  }
  
  return (
    <>
      <section className="mt-5 text-center">
        <h1>{data}</h1>
        <button onClick={updateData}>update</button>
      </section>
    </>
  );
}

console.warn("________________");
