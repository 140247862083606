import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import ProductsList from "./TrustSupportExpert";
import { HeadingDefault} from './Headings';
import irshadulhaq from "../img/irshad-ul-haq.png";
export default function WhyUsHome() {
  return (
    <>
      <section className="whyUsHome">
        <ProductsList />
        <Container data-aos="fade-up">
            <Row className="content align-items-center">
              <Col xs={12} md={6} data-aos="fade-right">
                <HeadingDefault
                  title="Why Choose Us"
                  subtitle="Discover the CSP Advantage: Your Trusted Partner for Comprehensive Technology Solutions"
                />
                <p>
                  At CSP, we pride ourselves on being your comprehensive
                  solution provider across a diverse spectrum of services. With
                  our expertise spanning the fields of <b>CCTV, solar, network
                  infrastructure, IT equipment, communication systems, power
                  backup solutions, cabling infrastructure, security, access
                  control, and software integration</b>, we stand as your one-stop
                  destination for all your technology needs.
                </p>
                <ul>
                  <li>Expertise Across Multiple Domains</li>
                  <li>Tailored Solutions</li>
                  <li>Quality Assurance</li>
                  <li>Cutting-Edge Technology</li>
                  <li>Seamless Integration</li>
                  <li>Exceptional Customer Service</li>
                </ul>
                <p className="bold">
                  Choose CSP for Comprehensive Solutions, Unparalleled
                  Expertise, and Exceptional Service. Contact us today to
                  discover how we can empower your business for success.
                </p>
              </Col>
              <Col xs={12} md={6} className="text-center ceoimage" data-aos="fade-left">
                <Image src={irshadulhaq} alt="irshad ul haq" />
              </Col>
            </Row>
        </Container>
      </section>
    </>
  );
}
