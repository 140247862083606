import React from "react";
import pakmap from "../img/pakistan.jpg";
import { Container, Row, Col, Image } from "react-bootstrap";
import { HeadingDefault } from "./Headings";
import { Link } from "react-router-dom";
export default function Locations() {
  return (
    <>
      <section data-aos="fade-up">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={3}>
              <Image src={pakmap} alt="CSP Map" />
            </Col>
            <Col xs={6} md={6}>
              <HeadingDefault
                title="Since 1996 we have branches all over Pakistan"
                subtitle="Our Extensive Nationwide Network"
              />
              <p>
                Unified in Service: Leveraging Our Nationwide Network of 05
                Regional and 6 Area Offices Across Pakistan
              </p>
              <Link to="/Contact" className="btn btn-orange">
                Contact Us <i class="fa-solid fa-arrow-right"></i>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
