import React from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import Header from "./InnerHeader";
import headerbg from "../img/bg-inner.jpg";
import { HeadingDefault } from "./Headings";

import ClientSlider from "./ClientsSlider";
import QuichAccess from "./QuichAccess";


import member1 from "../img/team/irshadulHaq.jpg";
import member2 from "../img/team/noorAhmad.jpg";
import member3 from "../img/team/asifmehmood.jpg";
import member4 from "../img/team/ayaz.jpg";
import member5 from "../img/team/tanveer.jpg";

const teamMembers = [
  {
    name: "Irshad-Ul-Haq",
    position: "Director",
    image: member1,
    delay: "100"
  },
  {
    name: "Noor Ahmad",
    position: "CFO",
    image: member2,
    delay: "200"
  },
  {
    name: "Asif Mehmood",
    position: "Regional Manager",
    image: member3,
    delay: "300"
  },
  {
    name: "Muhammad Ayaz",
    position: "Business Development Executive",
    image: member4,
    delay: "400"
  },
  {
    name: "Tanveer Ahmad",
    position: "Branch Manager",
    image: member5,
    delay: "500"
  },
];
export default function Team() {
  return (
    <>
      <Header backgroundImage={headerbg} title="Meet Our Team" />
      <section data-aos="fade-up">
        <Container>
        <HeadingDefault
            title="Our Collaborative Staff"
            subtitle="Our expert and professional team brings years of experience and dedication to every project, ensuring unparalleled quality and customer satisfaction."
          />
          <Row className="justify-content-center">
            {teamMembers.map((member, index) => (
              <Col xs={12} md={3} key={index} className="teamMember mb-3"data-aos="zoom-in"data-aos-delay={member.delay}>
                <Card>
                  <Card.Img
                    variant="top"
                    src={member.image}
                    alt={member.name}
                  />
                  <Card.Body>
                    <Card.Title>{member.name}</Card.Title>
                    <Card.Text>{member.position}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <QuichAccess/>
      <ClientSlider />
    </>
  );
}
