import React from "react";
import Header from "./InnerHeader";
import headerbg from "../img/bg-inner.jpg";
import ContactForm from "./ContactForm";
import { Col, Row, Container, Image } from "react-bootstrap";
import map from "../img/pakistan.jpg";

export default function Contact() {
  const locations = [
    
    { name: "Display Center", address: "Office# 68-G Khan Plaza Multan Cantt",delay: "100", },
    {
      name: "Multan",
      address: "327-F, Shah Ruken-E-Alam Colony, Multan, 60000",
      delay: "200",
    },
    { name: "Faisalabad", address: "755-756 D Block sir Syed Town Faisalabad",delay: "300", },
    {
      name: "Islamabad",
      address: "Double Road Near Rawalpindi stadium IJP Road Islamabad",
      delay: "400",
    },
    {
      name: "Sahiwal",
      address: "House #70 fateh sher Colony (humma Colony) Sahiwal",
      delay: "500",
    },
  ];
  const areas = [
    { name: "Karachi" },
    { name: "Shakhar" },
    { name: "Peshawar" },
    { name: "Quetta" },
    { name: "Sarghoda" },
    { name: "Gujranwala" },
  ];
  const cardData = [
    {
      title: "Head Office",
      content: "12-1, block A2 Opp. Cine Star, Township, Lahore",
      icon: "fa-solid fa-location-dot",
      delay: "100",
    },
    {
      title: "Call",
      content: "+92-32-6066-7666",
      icon: "fa-solid fa-phone",
      delay: "200",
    },
    {
      title: "Email",
      content: "info@csp.com.pk",
      icon: "fa-solid fa-envelope",
      delay: "300",
    },
  ];
  return (
    <>
      <Header backgroundImage={headerbg} title="Contact US" />
      <section className="contact"  data-aos="fade-up">
        <Container>
          <Row className="locations justify-content-center mb-5">
            {locations.map((location, index) => (
              <Col className="col mb-3" md={4} sm={6} xs={12} key={index} data-aos="zoom-in" data-aos-delay={location.delay}>
                <h6>{location.name}</h6>
                <p>{location.address}</p>
              </Col>
            ))}
          </Row>
          <Row className="justify-content-center mb-5 access">
            {cardData.map((card, index) => (
              <Col
                key={index}
                xs={6}
                md={4}
                className="card-container"
                data-aos="zoom-in"
                data-aos-delay={card.delay}
              >
                <Row>
                  <Col xs={6} md={2}>
                    <span className="icon">
                      <i className={`fa-solid ${card.icon}`}></i>
                    </span>
                  </Col>
                  <Col xs={6} md={10}>
                    <h6>{card.title}</h6>
                    <p>{card.content}</p>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="contactbg"  data-aos="fade-up">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} lg={7} className="mb-3">
              <h4 className="mb-3">Mandatory Requirements</h4>
              <ContactForm />
            </Col>
            <Col xs={12} lg={5}>
              <Image src={map} />
            </Col>
          </Row>
        </Container>
      </section>
      <section  data-aos="fade-up">
        <Container>
          <Row className="justify-content-center">
            <h4>Area Offices / Residence Engineer</h4>
            {areas.map((location, index) => (
              <Col className="areas mb-2" key={index}>
                {location.name}
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
