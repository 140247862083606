import React from 'react';

export function HeadingDefault({title, subtitle}) {
  return (
  <div className='heading'>
    <h1>{title}</h1>
    <h6>{subtitle}</h6>
    </div>
    )
  ;
}

export function HeadingDefaultCenter({title, subtitle}) {
  return (
  <div className='heading center text-center'>
    <h1>{title}</h1>
    <h6>{subtitle}</h6>
    </div>
    )
  ;
}
export function HeadingWhite({title, subtitle}) {
  return (
  <div className='heading text-white'>
    <h1>{title}</h1>
    <h6>{subtitle}</h6>
    </div>
    )
  ;
}
export function HeadingDefaultWhite({title, subtitle}) {
  return (
  <div className='heading white text-white'>
    <h1>{title}</h1>
    <h6>{subtitle}</h6>
    </div>
    )
  ;
}

export function HeadingWhiteCenter({title, subtitle}) {
  return (
  <div className='heading center text-white text-center'>
    <h1>{title}</h1>
    <h6>{subtitle}</h6>
    </div>
    )
  ;
}
