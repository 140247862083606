import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image, Col, Container, Row, Card, CardBody } from "react-bootstrap";

// Banks
import askari from "../img/clients/askari-bank.png";
import bop from "../img/clients/bop.png";
import hbl from "../img/clients/hbl.png";
import ztbl from "../img/clients/ztbl.png";
import nationalsavings from "../img/clients/nationalsavings.png";
import nbp from "../img/clients/nbp.png";
// Gov
import ccri from "../img/clients/ccri.png";
import dgkpolice from "../img/clients/dgkpolice.png";
import eCoP from "../img/clients/electionCommissionofPakistan.png";
import govpunjab from "../img/clients/govpunjab.png";
import highcourt from "../img/clients/highcourt.png";
import hrdc from "../img/clients/hrdc.png";
import mWasteManagement from "../img/clients/multanWasteManagement.png";
import pakArmycmh from "../img/clients/pakArmycmh.png";
import pitb from "../img/clients/pitb.png";
import punjab_police from "../img/clients/punjab_police.png";
// Semi Gov
import ptcl from "../img/clients/ptcl.png";
import qAzamSolarPower from "../img/clients/quaid-e-AzamSolarPower.png";
import dss from "../img/clients/dss.png";
// Private
import barkatfoods from "../img/clients/barkatfoods.png";
import hondabreeze from "../img/clients/hondabreeze.png";
import hondamultan from "../img/clients/hondamultan.png";
import hyundaiOASIS from "../img/clients/hyundaiOASIS.png";
import idealprinterpackages from "../img/clients/idealprinterpackages.png";
import kisaanseed from "../img/clients/kisaanseed.png";
import rbavari from "../img/clients/rbavari.png";
import rehmatfoods from "../img/clients/rehmatfoods.png";
import yaqoobsoap from "../img/clients/yaqoobsoap.png";
import kkmart from "../img/clients/kkmart.png";

const ClientSlider = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const gov = [
    { client: ccri, alt: "anviz", delay: "100" },
    { client: dgkpolice, alt: "cisco", delay: "200" },
    { client: eCoP, alt: "cpplus", delay: "300" },
    { client: govpunjab, alt: "dlink", delay: "500" },
    { client: highcourt, alt: "hikvision", delay: "100" },
    { client: hrdc, alt: "homeywell", delay: "200" },
    { client: mWasteManagement, alt: "inim", delay: "300" },
    { client: pakArmycmh, alt: "itc", delay: "500" },
    { client: pitb, alt: "mikrotik", delay: "100" },
    { client: punjab_police, alt: "toa", delay: "200" },
  ];
  const semigov = [
    { client: ptcl, alt: "ptcl", delay: "100" },
    { client: qAzamSolarPower, alt: "Qaid e Azam Solar Power", delay: "200" },
    { client: dss, alt: "Danish School System", delay: "300" },
  ];
  const priv = [
    { client: barkatfoods, alt: "barkat foods", delay: "100" },
    { client: hondabreeze, alt: "honda breeze", delay: "200" },
    { client: hondamultan, alt: "honda multan", delay: "300" },
    { client: hyundaiOASIS, alt: "hyundai OASIS", delay: "500" },
    { client: idealprinterpackages, alt: "ideal printer packages", delay: "200" },
    { client: kisaanseed, alt: "kisaan seed", delay: "300" },
    { client: rbavari, alt: "rbavari", delay: "500" },
    { client: rehmatfoods, alt: "rehmat foods", delay: "100" },
    { client: yaqoobsoap, alt: "yaqoob soap", delay: "200" },
    { client: kkmart, alt: "kkmart", delay: "300" },
  ];
  const bank = [
    { client: askari, alt: "askari", delay: "100" },
    { client: bop, alt: "bop", delay: "200" },
    { client: hbl, alt: "hbl", delay: "300" },
    { client: ztbl, alt: "ztbl", delay: "500" },
    { client: nationalsavings, alt: "national savings", delay: "100" },
    { client: nbp, alt: "nbp", delay: "200" },
  ];
  const allClients = [
    ...gov.map(item => ({ ...item, type: "gov" })),
    ...semigov.map(item => ({ ...item, type: "semigov" })),
    ...priv.map(item => ({ ...item, type: "priv" })),
    ...bank.map(item => ({ ...item, type: "bank" })),
  ];

  return (
    <section className="clients-slider" data-aos="fade-up">
      <Container>
        <Row>
          <Slider {...settings}>
          {allClients.map((item, index) => (
                  <Col
                    key={index}
                    className="hvr-float-shadow"
                    data-aos="zoom-in"
                    data-aos-delay={item.delay}
                  >
                    <Image src={item.client} alt={item.alt} />
                  </Col>
                ))}
          </Slider>
        </Row>
      </Container>
      </section>
  );
};

export default ClientSlider;
