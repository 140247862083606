import React from "react";
import Header from "./InnerHeader";
import headerbg from "../img/bg-inner.jpg";
import { Card, CardBody, Col, Container, Image, Row } from "react-bootstrap";
import { HeadingDefault } from "./Headings";
import ClientSlider from "./ClientsSlider";
import QuichAccess from "./QuichAccess";

import accesscontrol from "../img/accesscontrol.jpg";
import cablingInfrastructure from "../img/cablingInfrastructure.jpg";
import communication from "../img/communication.jpg";
import itEquipment from "../img/itEquipment.jpg";
import network from "../img/network.jpg";
import power from "../img/power.jpg";
import cctv from "../img/cctv.jpg";
import securitysurviellances from "../img/securitysurviellances.jpg";
import solar from "../img/solar.jpg";
import software from "../img/software.jpg";
import internetservice from "../img/internetservice.jpg";
import sms from "../img/sms.jpg";

const items = [
  {
    title: "Solar",
    list: [ "Residential", "Commercial", "Agricultural", "Industrial", "Net metering", "Bank Lease"],
    icon: solar,
    alt: "Solar Energy Solution",
    delay: "100"
  },
  {
    title: "Security Surviellances",
    list: ["Zone Alarm System", "Walk Through Gates", "Scanner"],
    icon: securitysurviellances,
    alt: "Alt text for icon",
    delay: "200"
  },
  {
    title: "CCTV ",
    list: ["Analog Camera Setup","IP Camera Setup",],
    icon: cctv,
    alt: "Alt text for icon",
    delay: "300"
  },
  {
    title: "Access Control",
    list: ["RFID", "Finger Print Control", "Finger Print & Face Detection", "Attendance System"],
    icon: accesscontrol,
    alt: "Alt text for icon",
    delay: "400"
  },

  {
    title: "IT Equvalant",
    list: ["Server", "Desktop", "Laptop", "Projectors", "Printer", "Fax"],
    icon: itEquipment,
    alt: "IT Equvalant",
    delay: "500"
  },
  {
    title: "Network",
    list: ["Access Point", "Switches Manageable Switches", "Routers, Manageable Routers", "Optical Fiber cable, Radio link"],
    icon: network,
    alt: "Network",
    delay: "600"
  },
  {
    title: "Cabling Infrastructure",
    list: ["Fiber", "LAN", "WAN", "Wireless (Point to Point)", "Power Cabling", "Telephone Cabling"],
    icon: cablingInfrastructure,
    alt: "Cabling Infrastructure",
    delay: "700"
  },
  {
    title: "Communication",
    list: ["PABX", "Exchange", "IP Phone", "VOIP", "VPN", "Voice Line"],
    icon: communication,
    alt: "Communication",
    delay: "800"
  },
  {
    title: "Power",
    list: ["Online", "Realtime", "Offline", "UPS", "Stablizer", ],
    icon: power,
    alt: "Power",
    delay: "900"
  },
  {
    title: "Internet Service",
    list: ["Shared internet services", "Committed internet Services", "Over Fiber & Radio Link"],
    icon: internetservice,
    alt: "Internet Service",
    delay: "1000"
  },
  {
    title: "SMS",
    list: ["Promotional ", "Brand Updates and Announcements ", "Reminders"],
    icon: sms,
    alt: "sms",
    delay: "1100"
  },
  // {
  //   title: "Software",
  //   list: ["ERP (Enterprises Resource Planning)", "Jadeed (Munshi Financial)", "Hostel Management System", "Que Management System", "Feed Back System", "Point of sale Solution", "Food Point of Sale", "HR Solution"],
  //   icon: software,
  //   alt: "Software Development",
  //   delay: "1200"
  // },
  
];

export default function Services() {
  return (
    <>
      <Header backgroundImage={headerbg} title="Services" />
      <section className="services-item" data-aos="fade-up">
        <Container>
          <Row>
            <HeadingDefault
              title="Our Services"
              subtitle="Empowering Solutions for Your Needs"
            />
            
          </Row>
          <Row className="justify-content-center">
            {items.map((item, index) => (
              <Col key={index} xs={12} md={6} className="item mb-3 hvr-float-shadow" data-aos="zoom-in" data-aos-delay={item.delay}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs={12} md={5} className="service-type">
                        <h5>{item.title}</h5>
                        <p>{item.description}</p>
                        <ul>
                          {item.list.map((listItem, index) => (
                            <li key={index}>{listItem}</li>
                          ))}
                        </ul>
                      </Col>
                      <Col xs={12} md={7} className="icon">
                        <Image src={item.icon} alt={item.alt} />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <QuichAccess/>
      <ClientSlider />
    </>
  );
}
