import React from "react";
import Header from "./InnerHeader";
import headerbg from "../img/bg-inner.jpg";
import ProductsList from "./ProductsList";
import { HeadingDefault } from "./Headings";
import { Container } from "react-bootstrap";

import ClientSlider from "./ClientsSlider";
import QuichAccess from "./QuichAccess";

export default function Businesses() {
  return (
    <>
      <Header backgroundImage={headerbg} title="Businesses" />
      <section  data-aos="fade-up">
        <Container>
        <HeadingDefault
          title="Our Buinesses"
          subtitle="Driving Innovation, Building Futures: Your Partner in Technological Excellence"
        /></Container>
        <ProductsList />
      </section>
      <QuichAccess/>
      <ClientSlider />
    </>
  );
}
