import React from "react";
import { HeadingDefault } from "./Headings";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import gop from "../img/clients/govpunjab.png";
import pitb from "../img/clients/pitb.png";
import ptcl from "../img/clients/ptcl.png";
import dss from "../img/clients/dss.png";
export default function SuccessStory() {
  const successStory = [
    {
      company: "Govt of Punjab",
      title: "Installation of 3400 Security Surveillance Camera at Govt High Schools.",
      site: "600 School Sites in following Districts of Punjab (DG Khan, Muzaffar Garh, Vehari, Khanewal, Sarghoda, Gujranwal, Sahiwal)",
      amount: "27 Million",
      imageSrc: gop,
      delay: "100"
    },
    {
      company: "Govt of Punjab / PITB",
      title: "Installation of 550 Security Surveillance at Tehsil Head Quarter Hospital and District Head Quarter Hospital ",
      site: "All Punjab (36 District Head Quarter Hospital, 100 Tehsil Head Quarter Hospitals)",
      amount: "14.5 Million",
      imageSrc: pitb,
      delay: "200"
    },
    {
      company: "Govt of Punjab / PITB",
      title: "Extension of Project Installation of 550 Security Surveillance at Tehsil Head Quarter Hospital and District Head Quarter Hospital",
      site: "All Punjab Hospital’s (36 District Head Quarter Hospital, 100 Tehsil Head Quarter Hospitals)",
      amount: "14.5 Million",
      imageSrc: pitb,
      delay: "300"
    },
    {
      company: "Govt of Punjab",
      title: "Installation of 07 (VOIP) IP BASE PRI PABX Exchanges with 140 IP Telephony and 224 Analog Telephony at Danish Schools",
      site: "Danish school (Rahim Yar Khan, Hasil pur, Chistian, Fazal pur, DG Khan, Harnooli, Jand (Attock)",
      amount: "14.17 Million",
      imageSrc: dss,
      delay: "400"
    },
    {
      company: "Govt of Punjab",
      title: "Extension Of Project Installation of 07 (VOIP) IP BASE PRI PABX Exchanges with 140 IP Telephony and 224 Analog Telephony at Danish Schools",
      site: "Danish school (Rahim Yar Khan, Hasil pur, Chistian, Fazal pur, DG Khan, Harnooli, Jand (Attock)",
      amount: "14.17 Million",
      imageSrc: dss,
      delay: "500"
    },
    {
      company: "Govt of Punjab",
      title: "Installation of 15 Security Surveillance IP Camera AT Core Equipment of PTCL at Multi Sites and Recording Control at PTCL Data Center",
      site: "Garden Town Exchnage Kalma Chowk Lahore, CTH Exchange Mall Road Lahore, Gulberg Exchange Lahore, Data Center (Recording)",
      amount: "0.5 Million",
      imageSrc: ptcl,
      delay: "600"
    },
  ];
  return (
    <>
      <section className="successStory"  data-aos="fade-up">
        <Container className="content">
          <Row className="align-items-center">
            <Col xs={12} md={10}>
              <HeadingDefault
                title="Our Success Stories"
                subtitle="Empowering Growth: Stories of Triumph and Transformation"
              />
            </Col>
            <Col xs={12} md={2} className="text-end">
              <Link className="btn" to="/Projects">
                View More <i class="fa-solid fa-arrow-right"></i>
              </Link>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {successStory.map((card) => (
              <Col key={card.id} xs={12} md={6} className="mb-4">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col xs={12} md={4}>
                        <Card.Img variant="top" src={card.imageSrc} />
                      </Col>
                      <Col xs={6} md={8}>
                        <p>{card.company}</p>
                        <Card.Title>{card.title}</Card.Title>
                        <Card.Text>{card.site}</Card.Text>
                        <Card.Text>{card.text}</Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-end">Amount: <b>{card.amount}</b></Card.Footer>
                </Card>
              </Col>
            ))}
            <Col className="text-center mt-3"><Link className="btn" to="/Projects">
                View More <i class="fa-solid fa-arrow-right"></i>
              </Link></Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
