import React from "react";
import { HeadingWhiteCenter } from "./Headings";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import ProductsList from "./ProductsList";
export default function Businesses() {
  return (
    <>
      <section className="businesses" data-aos="fade-up">
        <div className="content">
          <Container>
            <Row className="justify-content-center align-items-center text-center">
              <HeadingWhiteCenter
                title="Our Businesses"
                subtitle="Uniting Technology Solutions for Your Success"
              />
            </Row>
          </Container>
          <ProductsList />
        </div>
      </section>
    </>
  );
}
