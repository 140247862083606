import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { HeadingDefault } from "./Headings";
import ClientSlider from "./ClientsSlider";
import QuichAccess from "./QuichAccess";

import Header from "./InnerHeader";
import headerbg from "../img/bg-inner.jpg";
import gop from "../img/clients/govpunjab.png";
import pitb from "../img/clients/ptcl.png";
import ptcl from "../img/clients/pitb.png";
import dss from "../img/clients/dss.png";
import army from "../img/clients/army.png";
import punjabpolice from "../img/clients/punjab_police.png";
import mepcoUSaid from "../img/clients/mepcoUSaid.png";
import smfood from "../img/clients/smfood.png";
import pakrail from "../img/clients/pakrail.png";

const successStory = [
  {
    company: "Govt of Punjab",
    title:
      "Installation of 3400 Security Surveillance Camera at Govt High Schools.",
    site: "600 School Sites in following Districts of Punjab (DG Khan, Muzaffar Garh, Vehari, Khanewal, Sarghoda, Gujranwal, Sahiwal)",
    amount: "27 Million",
    imageSrc: gop,
    delay: "100",
  },
  {
    company: "Govt of Punjab / PITB",
    title:
      "Installation of 550 Security Surveillance at Tehsil Head Quarter Hospital and District Head Quarter Hospital ",
    site: "All Punjab (36 District Head Quarter Hospital, 100 Tehsil Head Quarter Hospitals)",
    amount: "14.5 Million",
    imageSrc: pitb,
    delay: "200",
  },
  {
    company: "Govt of Punjab / PITB",
    title:
      "Extension of Project Installation of 550 Security Surveillance at Tehsil Head Quarter Hospital and District Head Quarter Hospital",
    site: "All Punjab Hospital’s (36 District Head Quarter Hospital, 100 Tehsil Head Quarter Hospitals)",
    amount: "14.5 Million",
    imageSrc: pitb,
    delay: "300",
  },
  {
    company: "Govt of Punjab",
    title:
      "Installation of 07 (VOIP) IP BASE PRI PABX Exchanges with 140 IP Telephony and 224 Analog Telephony at Danish Schools",
    site: "Danish school (Rahim Yar Khan, Hasil pur, Chistian, Fazal pur, DG Khan, Harnooli, Jand (Attock)",
    amount: "14.17 Million",
    imageSrc: dss,
    delay: "400",
  },
  {
    company: "Govt of Punjab",
    title:
      "Extension Of Project Installation of 07 (VOIP) IP BASE PRI PABX Exchanges with 140 IP Telephony and 224 Analog Telephony at Danish Schools",
    site: "Danish school (Rahim Yar Khan, Hasil pur, Chistian, Fazal pur, DG Khan, Harnooli, Jand (Attock)",
    amount: "14.17 Million",
    imageSrc: dss,
    delay: "500",
  },
  {
    company: "Govt of Punjab",
    title:
      "Installation of 15 Security Surveillance IP Camera AT Core Equipment of PTCL at Multi Sites and Recording Control at PTCL Data Center",
    site: "Garden Town Exchnage Kalma Chowk Lahore, CTH Exchange Mall Road Lahore, Gulberg Exchange Lahore, Data Center (Recording)",
    amount: "0.5 Million",
    imageSrc: ptcl,
    delay: "600",
  },
  {
    company: "Pakistan Army (Military)",
    title:
      "Installation of 100 Security Surveillance IP Camera’s",
    site: "CMH Multan",
    amount: "2.0 Million",
    imageSrc: army,
    delay: "600",
  },
  {
    company: "Pakistan Army (Military)",
    title: "Installation of Biomatric Access Control System",
    site: "CMH Multan",
    amount: "0.2 Million",
    imageSrc: army,
    delay: "700",
  },
  {
    company: "Punjab Police",
    title: "Installation of 100 Lines PRI PABX Exchange at Counter Terrarius Department",
    site: "Counter Terrarius Department Bahawalpur",
    amount: "0.52 Million",
    imageSrc: punjabpolice,
    delay: "800",
  },
  {
    company: "MEPCO USAID",
    title: "Installation 07 EMURSUN UPS at USAID / MEPCO Project",
    site: "Musapak Subdivision Multan, Mumtazaabad Subdivision Multan, Mianchanu Subdivision, Kabirwala Subdivision, Khanewal Subdivision",
    amount: "0.42 Million",
    imageSrc: mepcoUSaid,
    delay: "800",
  },
  {
    company: "Pakistan Railways",
    title: "Installation 32 Lines PABX Exchange at Pak Rail Head Quarter",
    site: "Pak Rail Head Quarter IT Directorate Lahore",
    amount: "0.3 Million",
    imageSrc: pakrail,
    delay: "800",
  },
  {
    company: "SM Food",
    title: "KASPERSKY Antivirus for 300 Users for 1 Year",
    site: "SM Food, Volka Food",
    amount: "0.38 Million",
    imageSrc: smfood,
    delay: "800",
  },
];
export default function Projects() {
  return (
    <>
      <Header backgroundImage={headerbg} title="Projects" />
      <section className="projects" data-aos="fade-up">
        <Container>
          <HeadingDefault
            title="Our Projects"
            subtitle="With great pride, we introduce Computer Service Point, a 20-year-old organization dedicated to sales and services. With 11 business offices across various districts of Pakistan, we continuously expand our network to provide seamless access to our valued customers. Additionally, we are excited to showcase the multitude of projects we have undertaken in recent times."
          />
          <Row className="justify-content-center">
            {successStory.map((card) => (
              <Col
                key={card.id}
                xs={12}
                md={6}
                className="mb-4"
                data-aos="zoom-in"
                data-aos-delay={card.delay}
              >
                <Card>
                  <Card.Body>
                    <Row>
                      <Col xs={12} md={4}>
                        <Card.Img variant="top" src={card.imageSrc} />
                      </Col>
                      <Col xs={6} md={8}>
                        <p>{card.company}</p>
                        <Card.Title>{card.title}</Card.Title>
                        <Card.Text>{card.site}</Card.Text>
                        <Card.Text>{card.text}</Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-end">
                    Amount: <b>{card.amount}</b>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <QuichAccess />
      <ClientSlider />
    </>
  );
}
